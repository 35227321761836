.hero {
  margin: 3.75rem auto 0;
  min-height: 16.25rem;
  width: 100%;
  position: relative;
  background-color: #dde5ea;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  @include breakpoint($lg) {
    margin: 0 auto;
    height: 36em;
  }

  &::before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(52, 56, 81, .8);
  }
}

.hero--small {
  margin: 3.75rem auto 0;
  min-height: 8.75rem;
  width: 100%;
  position: relative;
  background-color: $dark-light;

  @include breakpoint($lg) {
    height: 12.5em;
  }

  &::before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(52, 56, 81, .8);
  }
}

.hero__wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  max-width: 40em;
  z-index: 1;

  .hero__title {
    color: $bg-color;

    @include breakpoint($md) {
      font-size: 2.625em;
      line-height: 3.125rem;
    }
  }
}

.page-content {
  max-width: 52.5rem;
  margin: 0 auto;
  padding: 2.5em 0;

  @include breakpoint($md) {
    padding: 3.75rem 0;
  }
}

.blog {
  background-color: #f9f9f9;
}

.post-list {
  padding-top: 2.5em;
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 auto;

  @include breakpoint($md) {
    padding-top: 5em;
  }
}
